import React, { useEffect, useState, useRef} from "react";
import { useParams } from 'react-router-dom';
import { Box } from "@mui/material";
import Header from "../../components/Header";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SellIcon from '@mui/icons-material/Sell';
import SearchIcon from '@mui/icons-material/Search';
import { JSONToHTMLTable } from '@kevincobain2000/json-to-html-table'
import '../../components/table.scss';

import api from '../../api';

const Venda = () => {
    const [loading, setLoading] = useState(false);
    const [codigoDaVenda, setCodigoDaVenda] = useState('');
    const [data, setData] = useState('');
    const codigoDaVendaRef = useRef(null);
    const [accessDenyErrorMessage, setAccessDenyErrorMessage] = useState("");

    const handleInputChange = (event) => {
        setCodigoDaVenda(event.target.value);
      };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter')
        { event.preventDefault(); 
            if(!loading) 
            handleSearch(event);
        }
    };

    async function handleSearch (event, id = null) {
        setLoading(true);
        
        if(event){
            event.preventDefault();
        }
        setData({"localizando...":null});
          try{
            let p = codigoDaVenda ? codigoDaVenda : id;
            const response = await api.get(`server/api_mercado_livre_direto?api=mlOrders&p=${p}`);
           
            if (response.data.message) {
                setData(response.data.message);
            }
        }
        catch (error){
            setData(error.response.data);
        }
        // Limpar o campo
        setCodigoDaVenda('');
        // Colocar o foco novamente no campo
        codigoDaVendaRef.current.focus();

        setLoading(false);
        
      };
      
    const {id} = useParams();
    useEffect(() => {
        if (id && id !== '') {
            setCodigoDaVenda(id);
            handleSearch(null,id);
        }
    // eslint-disable-next-line
    }, [id, setCodigoDaVenda]); 

    useEffect(() => {
        checkCustomerServiceAccess();
        document.title = 'Venda';
    }, []);
    async function checkCustomerServiceAccess () {

        try{
          await api.get(`server/log?screen=apiVenda`);

          const response = await api.get(`server/customer_service?id=5`);
  
          if (response.data.status !== 'success' ){
            setAccessDenyErrorMessage("Acesso negado.");
          }
  
          const active = response.data.message.service.active;
          if (active !== 1) {
            setAccessDenyErrorMessage("Módulo inativo");
          }
  
          const hoje = new Date();
          const expireDate = new Date(response.data.message.customerService.expireDate);
          if (expireDate < hoje) {
            setAccessDenyErrorMessage("Acesso expirado, entre em contato conosco para renovar seu acesso");
          } 
          
        }
        catch (error){
          //Alerta de acesso negado
          setAccessDenyErrorMessage("Acesso negado.");
  
          console.error('Acesso negado:', error); // Se houver um erro, escreve no console
  
        }
      };
  
      if (accessDenyErrorMessage !== "")
      {
        return (
          <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Header title="Api do Mercado Livre - Venda" subtitle="Acesse as informações diretamente da api do mercado livre (sem cache)"/>
            </Box>
            <Alert severity="error">{accessDenyErrorMessage}</Alert>
          </Box>
        )
      }
  
    return (
    <Box m="20px">
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Header title="Api do Mercado Livre - Venda" subtitle="Acesse as informações diretamente da api do mercado livre (sem cache)"/>
        </Box>
        
        <Paper component="form" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 385 }}>
            <IconButton sx={{ p: '10px' }} aria-label="menu">
                <SellIcon/>
            </IconButton>
            <InputBase sx={{ ml: 1, flex: 1 }} 
                       placeholder="Código da venda. Ex: 2000001234512345" 
                       inputProps={{ 'aria-label': 'Código da venda' }} 
                       value={codigoDaVenda} 
                       onChange={handleInputChange} 
                       onKeyDown={handleKeyDown}
                       ref={codigoDaVendaRef}
                       />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="buscar" onClick={handleSearch} disabled={loading}>
                <SearchIcon />
            </IconButton>
            
        </Paper>
        
        <div style={{width: '500px'}} id="reader"></div>
       
        <Divider sx={{ height: 2, m: 0.5 }} orientation="horizontal" />
        <JSONToHTMLTable data={data} tableClassName="table table-bordered table-sm"/>
        
    </Box>
    
    )
};
export default Venda;
