export const gridLocalization_ptBr = {
    // Root
    noRowsLabel: 'Sem linhas',
    noResultsOverlayLabel: 'Nenhum resultado encontrado.',
  
    // Density selector toolbar button text
    toolbarDensity: 'Densidade',
    toolbarDensityLabel: 'Densidade',
    toolbarDensityCompact: 'Compacta',
    toolbarDensityStandard: 'Padrão',
    toolbarDensityComfortable: 'Confortável',
  
    // Columns selector toolbar button text
    toolbarColumns: 'Colunas',
    toolbarColumnsLabel: 'Selecionar colunas',
  
    // Filters toolbar button text
    toolbarFilters: 'Filtro',
    toolbarFiltersLabel: 'Mostrar filtro',
    toolbarFiltersTooltipHide: 'Esconder filtro',
    toolbarFiltersTooltipShow: 'Mostrar filtro',
    toolbarFiltersTooltipActive: (count) =>
      count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`,
  
    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'Pesquisar...',
    toolbarQuickFilterLabel: 'Pesquisar',
    toolbarQuickFilterDeleteIconLabel: 'Limpar',
  
    // Export selector toolbar button text
    toolbarExport: 'Exportar',
    toolbarExportLabel: 'Exportar',
    toolbarExportCSV: 'Baixar como CSV',
    toolbarExportPrint: 'Imprimir',
    toolbarExportExcel: 'Baixar como Excel',
  
    // Columns panel text
    columnsPanelTextFieldLabel: 'Encontrar coluna',
    columnsPanelTextFieldPlaceholder: 'Título da coluna',
    columnsPanelDragIconLabel: 'Rearranjar coluna',
    columnsPanelShowAllButton: 'Mostrar todas',
    columnsPanelHideAllButton: 'Esconder todas',
  
    // Filter panel text
    filterPanelAddFilter: 'Adicionar filtro',
    filterPanelRemoveAll: 'Remover todos',
    filterPanelDeleteIconLabel: 'Excluir',
    filterPanelLogicOperator: 'Operador lógico',
    filterPanelOperator: 'Operador',
    filterPanelOperatorAnd: 'E',
    filterPanelOperatorOr: 'Ou',
    filterPanelColumns: 'Colunas',
    filterPanelInputLabel: 'Valor',
    filterPanelInputPlaceholder: 'Valor do filtro',
  
    // Filter operators text
    filterOperatorContains: 'contém',
    filterOperatorEquals: 'é igual a',
    filterOperatorStartsWith: 'começa com',
    filterOperatorEndsWith: 'termina com',
    filterOperatorIs: 'é',
    filterOperatorNot: 'não é',
    filterOperatorAfter: 'é depois de',
    filterOperatorOnOrAfter: 'é em ou depois de',
    filterOperatorBefore: 'é antes de',
    filterOperatorOnOrBefore: 'é em ou antes de',
    filterOperatorIsEmpty: 'está vazio',
    filterOperatorIsNotEmpty: 'não está vazio',
    filterOperatorIsAnyOf: 'é qualquer um de',
    'filterOperator=': '=',
    'filterOperator!=': '!=',
    'filterOperator>': '>',
    'filterOperator>=': '>=',
    'filterOperator<': '<',
    'filterOperator<=': '<=',
  
    // Header filter operators text
    headerFilterOperatorContains: 'Contém',
    headerFilterOperatorEquals: 'Igual a',
    headerFilterOperatorStartsWith: 'Começa com',
    headerFilterOperatorEndsWith: 'Termina com',
    headerFilterOperatorIs: 'É',
    headerFilterOperatorNot: 'Não é',
    headerFilterOperatorAfter: 'É depois de',
    headerFilterOperatorOnOrAfter: 'É em ou depois de',
    headerFilterOperatorBefore: 'É antes de',
    headerFilterOperatorOnOrBefore: 'É em ou antes de',
    headerFilterOperatorIsEmpty: 'Está vazio',
    headerFilterOperatorIsNotEmpty: 'Não está vazio',
    headerFilterOperatorIsAnyOf: 'É qualquer um de',
    'headerFilterOperator=': 'Igual a',
    'headerFilterOperator!=': 'Diferente de',
    'headerFilterOperator>': 'Maior que',
    'headerFilterOperator>=': 'Maior ou igual a',
    'headerFilterOperator<': 'Menor que',
    'headerFilterOperator<=': 'Menor ou igual a',
  
    // Filter values text
    filterValueAny: 'qualquer',
    filterValueTrue: 'verdadeiro',
    filterValueFalse: 'falso',
  
    // Column menu text
    columnMenuLabel: 'Menu',
    columnMenuShowColumns: 'Mostrar colunas',
    columnMenuManageColumns: 'Gerenciar colunas',
    columnMenuFilter: 'Filtrar',
    columnMenuHideColumn: 'Esconder coluna',
    columnMenuUnsort: 'Remover ordenação',
    columnMenuSortAsc: 'Ordenar de forma ascendente',
    columnMenuSortDesc: 'Ordenar de forma descendente',
  
    // Column header text
    columnHeaderFiltersTooltipActive: (count) =>
      count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`,
    columnHeaderFiltersLabel: 'Mostrar filtros',
    columnHeaderSortIconLabel: 'Ordenar',
  
    // Rows selected footer text
    footerRowSelected: (count) =>
      count !== 1
        ? `${count.toLocaleString()} linhas selecionadas`
        : `${count.toLocaleString()} linha selecionada`,
  
    // Total row amount footer text
    footerTotalRows: 'Total de linhas:',
  
    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
      `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,
  
    // Checkbox selection text
    checkboxSelectionHeaderName: 'Seleção',
    checkboxSelectionSelectAllRows: 'Selecionar todas as linhas',
    checkboxSelectionUnselectAllRows: 'Desselecionar todas as linhas',
    checkboxSelectionSelectRow: 'Selecionar linha',
    checkboxSelectionUnselectRow: 'Desselecionar linha',
  
    // Boolean cell text
    booleanCellTrueLabel: 'sim',
    booleanCellFalseLabel: 'não',
  
    // Actions cell more text
    actionsCellMore: 'mais',
  
    // Column pinning text
    pinToLeft: 'Fixar à esquerda',
    pinToRight: 'Fixar à direita',
    unpin: 'Soltar',
  
    // Tree Data
    treeDataGroupingHeaderName: 'Grupo',
    treeDataExpand: 'ver filhos',
    treeDataCollapse: 'esconder filhos',
  
    // Grouping columns
    groupingColumnHeaderName: 'Grupo',
    groupColumn: (name) => `Agrupar por ${name}`,
    unGroupColumn: (name) => `Parar de agrupar por ${name}`,
  
    // Master/detail
    detailPanelToggle: 'Painel de detalhes',
    expandDetailPanel: 'Expandir',
    collapseDetailPanel: 'Recolher',
  
    // Used core components translation keys
    MuiTablePagination: {},
  
    // Row reordering text
    rowReorderingHeaderName: 'Reordenar linha',
  
    // Aggregation
    aggregationMenuItemHeader: 'Agrupar',
    aggregationFunctionLabelSum: 'soma',
    aggregationFunctionLabelAvg: 'média',
    aggregationFunctionLabelMin: 'mínimo',
    aggregationFunctionLabelMax: 'máximo',
    aggregationFunctionLabelSize: 'tamanho',
  };