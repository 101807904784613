
import { useContext } from "react";
import { Context } from './AuthProvider';
import { Navigate, Outlet } from "react-router-dom";

const Private = () => {
  //const [hasSession, setHasSession] = useState(false);
  const { loading, authenticated } = useContext(Context);
  
  if (loading) {
    return <h1>...</h1>;
  }

  return !authenticated ? <Navigate to="/login" /> : <Outlet /> ;
};

export default Private;