import React, { useContext , useEffect, useState } from 'react';
import { Context } from './AuthProvider';

import './login.scss'; // Importa estilos locais
import { Margin } from '@mui/icons-material';

const Login = () => {
    const { handleLogin, handleResetPassword } = useContext(Context);
    const [isRightPanelActive, setIsRightPanelActive] = useState(false);
    const [errorMessage,setErrorMessage] = useState('');
    const [IsResetPassword, setIsResetPassword] = useState(false);
    const [inputEmail, setInputEmail] = useState('');
    const [inputPassword, setInputPassword] = useState('');
  
    const handleSignUpClick = () => {
      setIsRightPanelActive(true);
    };
  
    const handleSignInClick = () => {
      setIsRightPanelActive(false);
    };
    
    const handleLinkMLAccountClick = () => {
      window.location.href = "https://ml.num.click/server/auth?source=https://ml.num.click/login?y=1";
    };
    
    async function handleLoginFormSubmit (event) {
      
      event.preventDefault();
      
      const errorResponse  = await handleLogin(inputEmail, inputPassword);
      
      setErrorMessage( errorResponse ? errorResponse.message : null);
      
    };
    
    async function handleResetPasswordClick (event){
      event.preventDefault();
      const errorResponse  = await handleResetPassword(inputEmail);
      setErrorMessage( errorResponse.message );
    };
  
    useEffect(() => {
      const url = new URL(window.location.href);
      if (url.searchParams.get("y") === "1" && url.searchParams.has("k")) {
        var Kvalue = url.searchParams.get("k");
        var k = atob(Kvalue);
        var me = JSON.parse(k);
        const tituloContaNova = document.getElementById('tituloContaNova');
        const btnLinkMLAccount = document.getElementById('btnLinkMLAccount');
        var txtContaNovaSpan = document.querySelector('#txtContaNova span');
        
        btnLinkMLAccount.style.display = 'none';

        if (me.newUser){
          tituloContaNova.textContent = 'Tudo Certo!';
          txtContaNovaSpan.textContent = `Oi ${me.name}, em breve você receberá um email na sua conta (${me.email}) com uma senha inicial para acessar o sistema.`;

        } else {
          tituloContaNova.textContent = 'Tudo Certo!';
          txtContaNovaSpan.textContent = `Oi ${me.name}, a sua conta já está conectada ao sistema, caso necessite de suporte para acessar a conta envie um email para suporte@ml.num.click.`;
        }
        
        setIsRightPanelActive(true);
      } else {
        setIsRightPanelActive(false);
      }
    }, []);
  
    return (
      <div className='login-page'>
        <h2 style={{marginBottom: 0, color: '#222'}}>MegaLupa.num.click</h2>
        <h6  style={{marginTop: 0, color: '#777' }}>by Crinove Sistemas de Informação</h6>
        <div className={`container ${isRightPanelActive ? "right-panel-active" : ""}`} id="container">
          <div className="form-container sign-up-container">
            <form action="#">
              <h1 id='tituloContaNova'>Crie sua conta</h1>
              <p id='txtContaNova'><span>clique no botão abaixo para criar sua conta na plataforma e ativar o acesso ao mercado live</span></p>
              <button id="btnLinkMLAccount" type="button" onClick={handleLinkMLAccountClick}>Criar conta via Mercado Livre</button>
            </form>
          </div>
          <div className="form-container sign-in-container">
            <form id="loginForm" onSubmit={handleLoginFormSubmit}>
              <h1>{!IsResetPassword ? 'Entrar' :  'Reset Senha' }</h1>
              <span>{!IsResetPassword ? 'Acesse usando seu email e senha já cadastrados' :  'Insira seu e-mail cadastrado para redefinir sua senha' }</span>
              <input id="email" type="email" placeholder="Email" required  onChange={e => setInputEmail(e.target.value)}  />
              {!IsResetPassword && ( <><input id="password" type="password" placeholder="Senha" required  onChange={e => setInputPassword(e.target.value)}/>
              <button id="btnLogIn" type="submit" disabled={!inputEmail || !inputPassword}>Entrar</button></>)}
              {IsResetPassword && <button id="btnResetPassword" type="button" disabled={!inputEmail}  onClick={handleResetPasswordClick}>Redefinir Senha</button>}
              <a id="linkResetPassword" href='/'  onClick={(e) => {e.preventDefault(); setIsResetPassword(!IsResetPassword)}}>{!IsResetPassword ? 'Esqueceu a senha?' :  'Voltar ao login' }</a>
              <p id='txtErrorMessage'><span>{errorMessage}</span></p>
            </form>
          </div>
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-left">
                <h1>Já tem cadastro?</h1>
                <p>Basta 'iniciar a sessão' para entrar na plataforma.</p>
                <button className="ghost" id="signIn" onClick={handleSignInClick}>Iniciar sessão</button>
              </div>
              <div className="overlay-panel overlay-right">
                <h1>Olá, amigo!</h1>
                <p>Crie a sua conta em nossa plataforma para ter acesso</p>
                <button className="ghost" id="signUp" onClick={handleSignUpClick}>Cadastre-se</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default Login;
  
