import React, { useState, useEffect} from "react";
import { Box ,Typography, useTheme  } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { gridLocalization_ptBr } from "../../const/gridLocale";
import { tokens } from "../../theme";
import { Link } from 'react-router-dom'; // Certifique-se de importar o Link do React Router
import { useHistory } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import Select from '@mui/material/Select';

import Header from "../../components/Header";
import api from '../../api';
import SearchIcon from '@mui/icons-material/Search';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const ControleDePedidos = () => {
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [optionsPeriodo, setOptionsPeriodo] = React.useState([]); // Inicializa com array vazio
    const [alertProcessingMessage, setAlertProcessingMessage] = useState(false);
    const [accessDenyErrorMessage, setAccessDenyErrorMessage] = useState("");

    const monthNames = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
       
    const [periodo, setPeriodo] = React.useState(null); // Inicializa com null   React.useState(optionsPeriodo[0].value);
    const options = [
        { value: 'cancelled', label: 'Cancelados' },
        { value: 'cancelled-WithoutInvoice', label: 'Cancelados s/ nota de devolução' },
        { value: 'paid', label: 'Pagas' },
        { value: 'all', label: 'Todas' },
    ];
    const [status, setStatus] = React.useState(options[0].value);
    const [snackbar, setSnackbar] = React.useState(null);
    const handleCloseSnackbar = () => setSnackbar(null);

    function loadPeriodos(dataLimite) {

        let dataAtual = new Date(); 
        const newOptionsPeriodo = [];
        for (let i = 12; i > 0; i--) {
          if (dataAtual > dataLimite) {
            newOptionsPeriodo.push({
                value: formatdateFromDateTo(dataAtual),
                label: `${monthNames[dataAtual.getMonth()]} - ${dataAtual.getFullYear()}`,
            });
            }  
            //reduz 1 mes    
            dataAtual = new Date(dataAtual.getFullYear(), dataAtual.getMonth() - 1, dataAtual.getDate());
        };
        setOptionsPeriodo(newOptionsPeriodo);

    }
    
    const columns = [
     {field :"id", 
        headerName: "Venda", 
        width: 148,
        renderCell: (params) => {
          const vendaId = params.value;
          return (
            <Typography to={`/api-venda/${vendaId}`} 
                  target="_blank"
                  title="Clique para ver os detalhes desta venda na API do ML"
                  sx={{ textDecoration: "none" }}
                  color={colors.grey[100]}
                  component={Link}
            >
              {vendaId}
            </Typography>
          );
        }
     }
    ,{field :"date_created", headerName: "Data", width: 90, valueFormatter: (params) => { return formatDate(params)}}
    ,{field :"Nf_Saida_id", headerName: "Nf_Saida_id", width: 10, headerClassName:"header-saida", cellClassName: "cell-saida", editable: false , hideable: true  }
    ,{field :"NF_Saida", headerName: "NF Saída", width: 70, headerClassName:"header-saida", cellClassName: "cell-saida"}
    ,{field :"NF_SaidaNatOper", headerName: "Nat de Operação", width: 120, headerClassName:"header-saida", cellClassName: "cell-saida"}
    ,{field :"NF_SaidaData", headerName: "Data", width: 90 , type: 'date', valueFormatter: (params) => { return formatDate(params)}, headerClassName:"header-saida", cellClassName: "cell-saida"}
    ,{field :"Nf_Entrada_id", headerName: "Nf_Entrada_id", width: 10, headerClassName:"header-entrada", cellClassName: "cell-entrada", editable: false , hideable: true  }
    ,{field :"Nf_Entrada", headerName: "NF entrada", width: 80, headerClassName:"header-entrada", cellClassName: "cell-entrada", editable: true }
    ,{field :"Nf_EntradaNatOper", headerName: "Nat de Operação", width: 120, headerClassName:"header-entrada", cellClassName: "cell-entrada", editable: true,
  
    // renderCell: (props) => {
    //   const { id, value, api, field, rowIndex, ...other } = props;

    //   const options = ['Opção 1', 'Opção 2', 'Opção 3']; // Sua lista de opções

    //   return (
    //     <FormControl fullWidth variant="standard">
    //       <Select
    //         {...other}
    //         value={value || ''}
    //         onChange={(event) => {
    //           //api.setEditCellValue({ id, field, value: event.target.value });
    //           //api.commitCellChange({ id, field });
    //         }}
    //         autoFocus
    //       >
    //         {options.map((option, index) => (
    //           <MenuItem key={index} value={option}>
    //             {option}
    //           </MenuItem>
    //         ))}
    //       </Select>
    //     </FormControl>
    //   );
    // },
  
     }
    ,{field :"Nf_EntradaData", 
          headerName: "Data", 
          width: 90, 
          type: 'date', 
          valueFormatter: (params) => { return formatDate(params)}, 
          headerClassName:"header-entrada", 
          cellClassName: "cell-entrada", 
          editable: true 
     }
    ,{field :"status", headerName: "Status", width: 80}
    ,{field :"expiration_date", headerName: "Data expiração", with: 100, type: 'date',valueFormatter: (params) => { return formatDate(params)}}
    ,{field :"date_closed", headerName: "Data fechamento", with: 100,type: 'date', valueFormatter: (params) => { return formatDate(params)}}
    ,{field :"status_pagamento", headerName: "Status pgto.", with: 80}
    ,{field :"total_paid_amount", headerName: "$ Pago", with: 50}
    ,{field :"transaction_amount", headerName: "$ transação", with: 50}
    ,{field :"transaction_amount_refunded", headerName: "$ reembolsado", with: 100}
    ,{field :"shipping_cost", headerName: "custo envio", with: 50}
    ,{field :"sku", headerName: "SKU", with: 80}
    ,{field :"qtd", headerName: "Qdte.", with: 30}
    ];

    function formatDate(params)
    {   
        if(params && params.value){
            const date = new Date(params.value);
            const formattedDate = date.toLocaleDateString("pt-BR");
            return formattedDate; 
        }
    }

    function formatdateFromDateTo(d) {
        
        const d1 = new Date(d.getFullYear(), d.getMonth(), 1);
        const d31 = new Date(d.getFullYear(), d.getMonth() + 1, 0);
        
        const ano1 = d1.getFullYear();
        const mes1 = String(d1.getMonth() + 1).padStart(2, '0');
        const dia1 = String(d1.getDate()).padStart(2, '0');

        const ano31 = d31.getFullYear();
        const mes31 = String(d31.getMonth() + 1).padStart(2, '0');
        const dia31 = String(d31.getDate()).padStart(2, '0');
        
        const dataFormatada = `&dateFrom=${ano1}-${mes1}-${dia1}T00:00:00-03:00&dateTo=${ano31}-${mes31}-${dia31}T23:59:59-03:00`;

        return dataFormatada;

    }
    
    const handleChangePeriodo = (event) => {
        setPeriodo(event.target.value);
    };

    const handleChangeStatus = (event) => {
        setStatus(event.target.value);
    };

    async function handleSearch (event) {
        setLoading(true);
        setData({});
        event.preventDefault();
          try{
            const response = await api.get(`server/orders?status=${status}&sort=date_desc&offset=0${periodo}`);
            if (response.data.message.data){
                setData(response.data.message.data);
            }
        }
        catch (error){
            setData(error.response.message);
        }
        setLoading(false);
    };

    const processRowUpdate = React.useCallback(
      async (newRow) => {
        console.log(newRow);
        console.log(newRow.Nf_Entrada_id);

        if (newRow.Nf_Entrada_id.length > 40 ){
          setSnackbar({ children: "Só é possível altear emissões adicionadas manualmente", severity: 'error' });
          return newRow;
        }

        if (newRow.Nf_EntradaData === "0000-00-00 00:00:00" || newRow.Nf_EntradaData === "")
          newRow.Nf_EntradaData = null;

        if (newRow.Nf_EntradaNatOper === "")
          newRow.Nf_EntradaNatOper = null;

        let numero = null;
        let serie = null;
          
        if (newRow.Nf_Entrada.trim() !== "") {

          let numeroSerie = newRow.Nf_Entrada.replace(/[^\d/\\-]/g, '');
          let delimitador = newRow.Nf_Entrada.match(/[\/\\-]/);
          
          if (delimitador) {
            delimitador = delimitador[0]; 
            let partes = numeroSerie.split(delimitador);
            numero = partes[0];
            serie = partes[1];
          } else {
            numero = numeroSerie;
          }
        }

        let nfEntradaManual = {
                                nfId : newRow.Nf_Entrada_id,
                                numero : numero,
                                serie : serie,
                                dataEmissao : newRow.Nf_EntradaData,
                                tpNf : 0,
                                naturezaOperacao : newRow.Nf_EntradaNatOper,
                                mlUserId : newRow.ml_user_id,
                                orderId : newRow.id,
                              };
        
        const response = await api.post('server/invoice', nfEntradaManual);
        
        newRow.Nf_Entrada_id = response.data.message;

        newRow.Nf_Entrada = numero + (serie ? ('/' + serie) : '');

        const updatedRow = { ...newRow, isNew: false };
        
        //setData(data.map((row) => (row.id === newRow.id ? updatedRow : row)));
        
        setSnackbar({ children: 'Informação salva com sucesso', severity: response.data.status });
        
        return newRow;

        // api.post('server/invoice', nfEntradaManual)
        //         .then(response =>{
                    
        //             newRow.Nf_Entrada_id = response.data.message;

        //             const updatedRow = { ...newRow, isNew: false };
        //             alert(1);
        //             setData(data.map((row) => (row.id === newRow.id ? updatedRow : row)));
        //             alert(2);
        //             setSnackbar({ children: 'Informação salva com sucesso', severity: response.data.status });
        //             alert(3);
        //         }) 
        //         .catch(error =>{
        //           setSnackbar({ children: error.response.data.message, severity: error.response.data.status });
        //         });

      },
      [],
    );
  
    const handleProcessRowUpdateError = React.useCallback((error) => {
      console.log(error);
      setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    
    useEffect(() => {
      checkCustomerServiceAccess();
      document.title = 'Controle de pedidos';
    }, []);

    useEffect(() => {
      if (optionsPeriodo.length > 0) {
          setPeriodo(optionsPeriodo[0].value);
      }
    }, [optionsPeriodo]);

    async function checkCustomerServiceAccess () {

      try{

        await api.get(`server/log?screen=controleDePedidos`);

        const response = await api.get(`server/customer_service?id=3`);
        
        if (response.data.status !== 'success' ){
          setAccessDenyErrorMessage("Acesso negado.");
        }

        const active = response.data.message.service.active;
        if (active !== 1) {
          setAccessDenyErrorMessage("Módulo inativo");
        }

        const hoje = new Date();
        const expireDate = new Date(response.data.message.customerService.expireDate);
        if (expireDate < hoje) {
          setAccessDenyErrorMessage("Acesso expirado, entre em contato conosco para renovar seu acesso");
        } 

        const orderLimitDate = response.data.message.customerService.config.getPastOrdersConfig.historicLimitDate;
        const orderProcessDate = response.data.message.customerService.config.getPastOrdersConfig.lastProcessedDate;
        const xmlLimitDate = response.data.message.customerService.config.getPastXMLsConfig.historicLimitDate;
        const xmlProcessDate = response.data.message.customerService.config.getPastXMLsConfig.lastProcessedDate;

        setAlertProcessingMessage((orderProcessDate > orderLimitDate || xmlProcessDate > xmlLimitDate));
        
        const numeroString = xmlLimitDate.toString();
        const ano = numeroString.substring(0, 4);
        const mes = numeroString.substring(4, 6) - 1;
        const dia = numeroString.substring(6, 8);
        
        const limitDate = new Date(ano, mes, dia);
        
        loadPeriodos(limitDate);

      }
      catch (error){
        //Alerta de acesso negado
        setAccessDenyErrorMessage("Acesso negado");
        console.error('Acesso negado:', error); // Se houver um erro, escreve no console
      }
    };

    if (accessDenyErrorMessage !== "")
    {
      return (
        <Box m="20px">
          {/* HEADER */}
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Header title="Controle de pedidos devolvidos" subtitle="Controle pedidos faturados/cancelados. Acompanhe notas pendentes, recupere impostos pagos em excesso" />
          </Box>
          <Alert severity="error">{accessDenyErrorMessage}</Alert>
        </Box>
      )
    }

    return (
      <Box m="20px">
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Controle de pedidos devolvidos" subtitle="Controle pedidos faturados/cancelados. Acompanhe notas pendentes, recupere impostos pagos em excesso"/>
        </Box>
        <FormControl sx={{ width: 245 }} size="small">
          <InputLabel id="label-status">Status</InputLabel>
          <Select labelId="label-status"
                  id="status-select"
                  value={status}
                  label="Status"
                  onChange={handleChangeStatus}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: 180 }} size="small">
          <InputLabel id="label-periodo"  shrink={true}>Período</InputLabel>
          <Select labelId="label-periodo"
                  id="periodo-select"
                  value={periodo}
                  // defaultValue={optionsPeriodo.length > 0 ? optionsPeriodo[0].value : ""}

                  label="Período"
                  onChange={handleChangePeriodo}
          >
            {optionsPeriodo.map((optionsPeriodo) => (
              <MenuItem key={optionsPeriodo.value} value={optionsPeriodo.value}>
                {optionsPeriodo.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <IconButton type="button" sx={{ p: '10px' }} aria-label="buscar" onClick={handleSearch} disabled={loading}>
            <SearchIcon />
          </IconButton>
        </FormControl>
        { alertProcessingMessage && (
          <Alert severity="warning">Olá! Estamos atualmente revisando seus dados históricos. Assim que esta mensagem desaparecer, significa que o processamento foi concluído. Agradecemos sua paciência e compreensão!</Alert>
        )}
        <Box sx={{
            "& .MuiDataGrid-columnHeader": { backgroundColor: '#00000020' },
          "& .header-saida": { backgroundColor: '#00ff003b'},
          "& .header-entrada": { backgroundColor: '#ff00003b'},
          "& .cell-saida": { backgroundColor: '#00ff0012'},
          "& .cell-entrada": { backgroundColor: '#ff000012'},
          "& .MuiDataGrid-footerContainer": {
            //borderTop: "none",
            // backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": { color: `${colors.greenAccent[200]} !important` },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": { color: `${colors.grey[300]} !important` },
          "& .MuiBadge-badge": { backgroundColor: `${colors.grey[300]} !important` },
        }}>
        {loading && ( <p>buscando ...</p>)}
        {data.length > 0 && (
            <DataGrid autoHeight 
                      pageSizeOptions={[15, 25, 50, 100]}
                      editMode="row"
                      processRowUpdate={processRowUpdate}
                      onProcessRowUpdateError={handleProcessRowUpdateError}
                      rows={data} 
                      columns={columns}
                      slots={{ toolbar: GridToolbar }}
                      density="compact"
                      localeText = {gridLocalization_ptBr}
                      initialState={{
                        ...data.initialState,
                        pagination: { paginationModel: { pageSize: 15 } },
                        columns: {
                          columnVisibilityModel: {
                            Nf_Entrada_id: false,
                            Nf_Saida_id: false,
                          }
                          ,
                        }
                      }}
            >
            </DataGrid>
        )}
        </Box>

        {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical:'top', horizontal:'right'}}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
        )}
      </Box>
    )
};
export default ControleDePedidos;
