import React, { useEffect } from "react";

import { Box } from "@mui/material";
import Header from "../../components/Header";

const Perguntas = () => {
    useEffect(() => {
        document.title = 'IA - Perguntas';
    }, []);
    return (

    <Box m="20px">
    {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Header title="IA - Perguntas" subtitle="Inteligência artificial para respostas automatizadas" />
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center">
            <p>Ops!<br/>em desenvolvimento...</p>
        </Box>
    </Box>

    )
};
export default Perguntas;
