import React, { useEffect, useState } from "react";

import { Box, Button, TextField, useMediaQuery, Snackbar, Alert } from "@mui/material";
import Header from "../../components/Header";

import Divider from '@mui/material/Divider';
import {  useFormik } from "formik";
import * as yup from "yup";
import api from '../../api';

import SaveIcon from '@mui/icons-material/Save';
import PasswordIcon from '@mui/icons-material/Password';
import ChangePassword from "./changePassword";

const MinhaConta = () => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('error');

    const [data, setData] = useState([]);
    const isNonMobile = useMediaQuery("(min-width:600px)"); 
   
    const contatoSchema = yup.object().shape({
        name: yup.string().required("nome em branco"),
        email: yup.string().required("email em branco").email('e-mail inválido'),
        phone_number: yup.string().required("telefone em branco"),
        cpfCnpj: yup.string()
                    .required("Documento obrigatório")
                    .test('check-cpfCnpj','cpf / cnpj inválido',function (value){return cpfCnpjIsValid(value);}),
    });

    const handleCloseAlert = ( event, reason) => {
        if (reason === 'clickaway'){
            return;
        }    
        setOpen(false);
    }

    const formikDadosDeContato = useFormik({
        initialValues: {name:'',email:'',phone_number:'',phone_number2:'', cpfCnpj:'',id:''},
        onSubmit:(values,{ setSubmitting, resetForm }) =>{
            
                api.post('server/customer', values)
                .then(response =>{
                    console.log('Resposta do servidor:', response.data);
                    setMessage('Dados de contato salvos com sucesso');
                    setStatus(response.data.status);
                    setOpen(true);
                    setSubmitting(false);
                }) 
                .catch(error =>{
                    setMessage(error.response.data.message);
                    setStatus(error.response.data.status);
                    setOpen(true);
                    setSubmitting(false);

                });
        },
        validationSchema: contatoSchema,
    });
    
    useEffect(() => {
        formikDadosDeContato.setValues({
            cpfCnpj : formatCpfCnpj(data.cpfCnpj) || '',
            email : data.email || '',
            id : data.id || '',
            name : data.name || '',
            phone_number : formatTelefone(data.phone_number) || '',
            phone_number2 : formatTelefone(data.phone_number2) || '',
        });
    // eslint-disable-next-line
    }, [data]);
    
    useEffect(() => {
        api.get(`server/log?screen=minhaConta`);

        document.title = 'Minha Conta';
        const fetchData = async () => {
            try {
                const response = await api.get('server/customer');
                setData(response.data.message);
            } catch (error) {
                // Trate o erro, por exemplo, exibindo uma mensagem ao usuário ou registrando-o no console.
                console.error('Erro ao buscar dados:', error);
            }
        };
    
        if (data.length === 0) {
            fetchData(); // Chama a função fetchData apenas se data.length for igual a zero
        }
    // eslint-disable-next-line
    }, []);

    
    const myHandleChange = (e) => {
        let field = e.target.name;
        let data = e.target.value;

        switch (field) {
            case 'cpfCnpj':
                data = formatCpfCnpj(data);    
                break;

            case 'phone_number':
            case 'phone_number2':
                data = formatTelefone(data);    
                break;
        
            default:
                break;
        }
        
        formikDadosDeContato.setFieldValue(field,data);
    }

    function formatCpfCnpj (valor){
        if (!valor) 
            return '';

        let data = valor.replace(/\D/g, "");
        // Checking data length to define if it is cpf or Cnpj
        if (data.length > 11) {
            // It's Cnpj
            let Cnpj = `${data.substr(0, 2)}.${data.substr(2,3)}.${data.substr(5,3)}/`;
            if (data.length > 12)
                Cnpj += `${data.substr(8, 4)}-${data.substr(12, 2)}`;
            else 
                Cnpj += data.substr(8);
            // Pass formatting for the data
            data = Cnpj;
        } else {
        // It's cpf
            let cpf = "";
            let parts = Math.ceil(data.length / 3);
            for (let i = 0; i < parts; i++) {
                if (i === 3) {
                    cpf += `-${data.substr(i * 3)}`;
                    break;
                }
                cpf += `${i !== 0 ? "." : ""}${data.substr(i * 3, 3)}`;
            }
            // Pass formatting for the data
            data = cpf;
        }
        return data;
    }

    function formatTelefone(valor) {
        if (!valor) 
            return '';

        let telefone = valor.replace(/\D+/g, "");

        let tamanho = telefone.length;

        if (tamanho > 10) {
            telefone = telefone.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
        } else if (tamanho > 6) {
            telefone = telefone.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
        } else if (tamanho > 2) {
            telefone = telefone.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
        } 
        else{
            telefone = valor;
        }
        return telefone;
    }

    function cpfCnpjIsValid(cpfCnpj) {
        
        cpfCnpj = cpfCnpj.replace(/[^\d]/g, '');
        
        if (cpfCnpj.length === 11) {
            let cpf = cpfCnpj;
            cpf = cpf.replace('.', '');
            cpf = cpf.replace('.', '');
            cpf = cpf.replace('-', '');
            let numeros, digitos, soma, i, resultado, digitos_iguais;
            digitos_iguais = 1;
            if (cpf.length < 11)
                return false;
            for (i = 0; i < cpf.length - 1; i++)
                if (cpf.charAt(i) != cpf.charAt(i + 1))
                {
                    digitos_iguais = 0;
                    break;
                }
            if (!digitos_iguais)
            {
                numeros = cpf.substring(0, 9);
                digitos = cpf.substring(9);
                soma = 0;
                for (i = 10; i > 1; i--)
                    soma += numeros.charAt(10 - i) * i;
                resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
                if (resultado != digitos.charAt(0))
                    return false;
                numeros = cpf.substring(0, 10);
                soma = 0;
                for (i = 11; i > 1; i--)
                    soma += numeros.charAt(11 - i) * i;
                resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
                if (resultado != digitos.charAt(1))
                    return false;
                return true;
            }
            else
                return false;

        } else if (cpfCnpj.length === 14) {

            let cnpj = cpfCnpj;
            cnpj = cnpj.replace(/[^\d]+/g,'');
            if(cnpj == '') return false;
            
            if (cnpj.length != 14)
                return false;
        
            // Elimina CNPJs invalidos conhecidos
            if (cnpj == "00000000000000" || 
                cnpj == "11111111111111" || 
                cnpj == "22222222222222" || 
                cnpj == "33333333333333" || 
                cnpj == "44444444444444" || 
                cnpj == "55555555555555" || 
                cnpj == "66666666666666" || 
                cnpj == "77777777777777" || 
                cnpj == "88888888888888" || 
                cnpj == "99999999999999")
                return false;
                
            // Valida DVs
            let tamanho = cnpj.length - 2
            let numeros = cnpj.substring(0,tamanho);
            let digitos = cnpj.substring(tamanho);
            let soma = 0;
            let pos = tamanho - 7;
            for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                    pos = 9;
            }
            let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(0))
                return false;
                
            tamanho = tamanho + 1;
            numeros = cnpj.substring(0,tamanho);
            soma = 0;
            pos = tamanho - 7;
            for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                    pos = 9;
            }
            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(1))
                return false;
                
            return true;
            
        } 
        // Retorna false se o CPF/CNPJ for válido
        return false;
    }

    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Minha Conta" 
                        subtitle="Nesta página, você pode visualizar suas informações, alterar sua senha, adicionar novos módulos e verificar os status de pagamento da sua conta" />
            </Box>
            <Divider sx={{ height: 2, m: 0.5 }} orientation="horizontal" />
            <form onSubmit={formikDadosDeContato.handleSubmit} className={`form-container`}>
                <h3>Dados de contato</h3>
                <Box 
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                        "& > div": {gridColumn: isNonMobile ? undefined : "span 4"},
                        }}
                >
                    <TextField 
                        fullWidth 
                        variant="outlined" 
                        type="text"
                        label="Nome" 
                        onBlur={formikDadosDeContato.handleBlur} 
                        onChange={formikDadosDeContato.handleChange}
                        name="name" 
                        value={formikDadosDeContato.values.name}
                        error = {!!formikDadosDeContato.touched.name && !!formikDadosDeContato.errors.name}
                        helperText={formikDadosDeContato.touched.name && formikDadosDeContato.errors.name}
                        sx = {{gridColumn:"span 2"}}
                    />
                    <TextField 
                        fullWidth 
                        disabled
                        variant="outlined" 
                        type="email" 
                        label="E-mail" 
                        onBlur={formikDadosDeContato.handleBlur} 
                        onChange={formikDadosDeContato.handleChange}
                        value={formikDadosDeContato.values.email}
                        name="email" 
                        error = {!!formikDadosDeContato.touched.email && !!formikDadosDeContato.errors.email}
                        helperText={formikDadosDeContato.touched.email && formikDadosDeContato.errors.email}
                        sx = {{gridColumn:"span 2"}}
                        inputProps={
                            { readOnly: true }
                        }  
                    />
                    <TextField 
                        fullWidth 
                        variant="outlined" 
                        type="text" 
                        label="Telefone para contato" 
                        onBlur={formikDadosDeContato.handleBlur} 
                        onChange={myHandleChange}
                        value={formikDadosDeContato.values.phone_number}
                        name="phone_number" 
                        error = {!!formikDadosDeContato.touched.phone_number && !!formikDadosDeContato.errors.phone_number}
                        helperText={formikDadosDeContato.touched.phone_number && formikDadosDeContato.errors.phone_number}
                        sx = {{gridColumn:"span 2"}} 
                    />
                    <TextField 
                        fullWidth 
                        variant="outlined" 
                        type="text" 
                        label="Celular" 
                        onBlur={formikDadosDeContato.handleBlur} 
                        onChange={formikDadosDeContato.handleChange}
                        value={formikDadosDeContato.values.phone_number2}
                        name="phone_number2" 
                        error = {!!formikDadosDeContato.touched.phone_number2 && !!formikDadosDeContato.errors.phone_number2}
                        helperText={formikDadosDeContato.touched.phone_number2 && formikDadosDeContato.errors.phone_number2}
                        sx = {{gridColumn:"span 2"}}
                    />
                    <TextField 
                        fullWidth 
                        variant="outlined" 
                        type="text" 
                        label="Cpf / Cnpj" 
                        onBlur={formikDadosDeContato.handleBlur} 
                        onChange={myHandleChange}
                        value={formikDadosDeContato.values.cpfCnpj}
                        name="cpfCnpj" 
                        error = {!!formikDadosDeContato.touched.cpfCnpj && !!formikDadosDeContato.errors.cpfCnpj}
                        helperText={formikDadosDeContato.touched.cpfCnpj && formikDadosDeContato.errors.cpfCnpj}
                        sx = {{gridColumn:"span 2"}}
                    />
                    <Button 
                        type="submit" 
                        color="secondary"
                        variant="contained" 
                        endIcon={< SaveIcon/>} 
                        style={{minWidth :'260px'}} 
                        sx = {{gridColumn:"span 2"}}
                        disabled={!formikDadosDeContato.isValid || formikDadosDeContato.isSubmitting }
                    >
                        Atualizar dados de contato
                    </Button>
                </Box>
            </form>
            <Snackbar autoHideDuration={4000} open={open} onClose={handleCloseAlert} anchorOrigin={{vertical:'top', horizontal:'right'}}>
                <Alert elevation={6} severity={status} onClose={handleCloseAlert}>{message}</Alert>
            </Snackbar>
            <Divider sx={{ height: 25, m: 0.5 }} orientation="horizontal" />
            <ChangePassword></ChangePassword>
        </Box>
    )
};
export default MinhaConta;
