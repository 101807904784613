import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Topbar from './scenes/global/Topbar.jsx';
import Sidebar from './scenes/global/Sidebar.jsx';
import Login from './components/Login.jsx';
import {ColorModeContext, useMode} from './theme.js'
import {CssBaseline, ThemeProvider} from '@mui/material';
import { AuthProvider } from './components/AuthProvider';
import Private from "./components/Private";

import MinhaConta from './scenes/minhaConta/index.jsx';
import ChangePassword from './scenes/minhaConta/changePassword.jsx';
import Devolucao from './scenes/devolucao/index.jsx';
import ControleDePedidos from './scenes/controleDePedidos/index.jsx';
import Dashboard from './scenes/dashboard/index.jsx';
import Perguntas from './scenes/perguntas/index.jsx';
import Anuncio from './scenes/apiAnuncio/index.jsx';
import Venda from './scenes/apiVenda/index.jsx';

function App() {
  const [theme, colorMode] = useMode();
  return (
    <AuthProvider>
      <ColorModeContext.Provider value = {colorMode}>
        <Routes>
            <Route path="/Login" element={<Login/>} />
            <Route path="*" element={<Main theme={theme} />} />
        </Routes>
      </ColorModeContext.Provider>
    </AuthProvider>
  );
}

function Main({theme}) {
  return (
      <ThemeProvider theme = {theme}>
        <CssBaseline />
        <div className="app">
            <Sidebar/>
            <main className="content">
              <Topbar />
              <Routes>
                <Route element={<Private />}>
                  <Route path="/" element={<ControleDePedidos />}  />
                  <Route path="/Envio-Devolucao" element={<Devolucao />}  />
                  <Route path="/Controle-de-pedidos" element={<ControleDePedidos />}  />
                  <Route path="/Perguntas" element={<Perguntas />}  />
                  <Route path="/api-anuncio" element={<Anuncio />}  />
                  <Route path="/api-venda/:id?" element={<Venda />} />
                  <Route path="/minha-conta" element={<MinhaConta />} />
                  <Route path="/minha-conta/change-password" element={<ChangePassword />} />
                  
                </Route>
                <Route path="*" element={<Navigate to="/" /> }  />
              </Routes>
          </main>
        </div>
      </ThemeProvider>
    
  );
}

export default App;
