import { useEffect, useState  } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link, useLocation  } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import SellIcon from '@mui/icons-material/Sell';
import logoMercadoLivre from '../../images/ml-icon.png';
import ListAltIcon from '@mui/icons-material/ListAlt';

import { tokens } from "../../theme";
import "react-pro-sidebar/dist/css/styles.css";
import 'remixicon/fonts/remixicon.css'

//import { Context } from '../../components/AuthProvider';


const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === to}
      style={{ color: colors.white[100], backgroundColor: selected === to ? '#00000050' : 'transparent', }}
      onClick={() => setSelected(to)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const location = useLocation();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(window.innerWidth <= 768);
  const [selected, setSelected] = useState(location.pathname);
  
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('')

  
  useEffect(() => {
    
      const user = JSON.parse(localStorage.getItem('user'));
      if (user){
        const firstName = user.name.split(' ')[0];
        setCustomerName(firstName);
        setCustomerEmail(user.email);
      }

  }, []); 

  return (
    <Box 
      sx={{
        "& .pro-sidebar-inner": {
           // eslint-disable-next-line
           background: `${colors.vivid[400]} !important`,
           // eslint-disable-next-line
           background: `-webkit-linear-gradient(to right, ${colors.vivid[400]}, ${colors.vivid[500]}) !important`,
	         // eslint-disable-next-line
           background: `linear-gradient(to right, ${colors.vivid[400]}, ${colors.vivid[500]}) !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: `${colors.white[200]} !important`,
        },
        "& .pro-menu-item.active": {
            color: `${colors.white[100]} !important`,
        },
        "& .MuiIconButton-root": {
            color: `${colors.white[100]} !important`,
        },
        "& .pro-icon-wrapper .pro-icon i": {
            fontSize: "18px",
        },
        "& .pro-sidebar.collapsed": {
            width: "50px",
            minWidth: "50px"
        },
        "& .pro-sidebar.collapsed .pro-inner-item": {
            padding: "5px 35px 5px 8px !important",
          },
          "& .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item ": {
            paddingLeft: "8px",
          }
      }}
    >

      <ProSidebar collapsed={isCollapsed} >
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.white[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <img alt="logo-num-click"
                     width="30px"
                     height="30px"
                    src={`/logo192.png`}
                    
                />
                <Typography variant="h4" color={colors.white[100]}>
                  num.click
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              {/* <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`../../assets/user.png`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box> */}
              <Box textAlign="center">
                <Typography
                  variant="h5"
                  color={colors.white[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {customerName}
                </Typography>
                <Typography variant="subtitle2" color={colors.white[200]}>
                  {customerEmail}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "0%"}>
            {/* <Item title="Dashboard" to="/" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected}/> */}
            <Item title="Controle de pedidos" to="/Controle-de-pedidos" icon={<ListAltIcon />} selected={selected} setSelected={setSelected}  />
            <Item title="Envio/Devolucao" to="/Envio-Devolucao" icon={<MultipleStopIcon />} selected={selected} setSelected={setSelected}  />
            {/* <Item title="IA Perguntas" to="/Perguntas" icon={< i className="ri-openai-fill"></i>} selected={selected} setSelected={setSelected} /> */}
            <SubMenu title = "API do Mercado Livre" icon={<img alt="Mercado Livre - Logo" width={30} src={logoMercadoLivre}/>} >
              <Item title="api de Anúncio" to="/api-anuncio" icon={<SellIcon/>} selected={selected} setSelected={setSelected} />
              <Item title="api de Venda" to="/api-venda" icon={<SellIcon/>} selected={selected} setSelected={setSelected} />
            </SubMenu>
         </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
