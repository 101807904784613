import React, { useEffect, useState , forwardRef } from "react";

import { Box, Button, TextField, useMediaQuery, Snackbar, Alert } from "@mui/material";

import Divider from '@mui/material/Divider';
import Formik , {  useFormik } from "formik";
import * as yup from "yup";

import api from '../../api';
import PasswordIcon from '@mui/icons-material/Password';

const ChangePassword = () => {
    
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('error');

    const handleCloseAlert = ( event, reason) => {
        if (reason === 'clickaway'){
            return;
        }    
        setOpen(false);
    }

    const isNonMobile = useMediaQuery("(min-width:600px)"); 

    const passwordSchema = yup.object().shape({
        password: yup.string().required("senha em branco"),
        newPassword: yup.string()
                        .required("Nova senha em branco") 
                        .min(8,"A senha deve ter pelo menos 8 caracteres, incluindo maiúsculas, minúsculas, números e caracteres especiais")
                        .test('check-uppercase', 'Pelo menos 1 letra maiúscula', function(value) {return (/[A-Z]/.test(value));})
                        .test('check-lowercase', 'Pelo menos 1 letra minúscula', function(value) {return (/[a-z]/.test(value));})
                        .test('check-number', 'Pelo menos 1 número', function(value) {return (/\d/.test(value));})
                        .test('check-special', 'Pelo menos 1 caractere especial', function(value) {return (/[!@#$%^&*(),.?":{}|<>]/.test(value));}),
                        
        newPasswordChecker: yup.string()
                               .required("nova senha em branco")
                               .oneOf([yup.ref('newPassword'), null], 'As senhas devem coincidir'),
    });

    const formikAlterarSenha = useFormik({
        initialValues: {password:'', newPassword:'', newPasswordChecker:'', action:'changePassword'},
        onSubmit:(values,{ setSubmitting, resetForm }) =>{
            
                api.post('server/auth', values)
                .then(response =>{
                    //setMessage(response.data.message);
                    setMessage("Senha alterada com sucesso");
                    setStatus(response.data.status);
                    setOpen(true);
                    resetForm();

                }) 
                .catch(error =>{
                    setMessage(`${error.response.data.message} - verifique se digitou a senha atual corretamente`);
                    setStatus(error.response.data.status);
                    setOpen(true);
                    setSubmitting(false);

                });
        },
        validationSchema: passwordSchema,
    });
    
    useEffect(() => {

    }, []);

    return (
        <>
            <form onSubmit={formikAlterarSenha.handleSubmit} className={`form-container`}>
                <h3>Alterar Senha</h3>
                <Box 
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                        "& > div": {gridColumn: isNonMobile ? undefined : "span 4"},
                        }}
                >
                   
                    <TextField 
                        fullWidth 
                        variant="outlined" 
                        type="password"
                        label="Nova Senha" 
                        onBlur={formikAlterarSenha.handleBlur} 
                        onChange={formikAlterarSenha.handleChange}
                        name="newPassword" 
                        value={formikAlterarSenha.values.newPassword}
                        error = {!!formikAlterarSenha.touched.newPassword && !!formikAlterarSenha.errors.newPassword}
                        helperText={formikAlterarSenha.touched.newPassword && formikAlterarSenha.errors.newPassword}
                        sx = {{gridColumn:"span 2"}}
                    />
                    <TextField 
                        fullWidth 
                        variant="outlined" 
                        type="password"
                        label="Confirme a nova senha" 
                        onBlur={formikAlterarSenha.handleBlur} 
                        onChange={formikAlterarSenha.handleChange}
                        name="newPasswordChecker" 
                        value={formikAlterarSenha.values.newPasswordChecker}
                        error = {!!formikAlterarSenha.touched.newPasswordChecker && !!formikAlterarSenha.errors.newPasswordChecker}
                        helperText={formikAlterarSenha.touched.newPasswordChecker && formikAlterarSenha.errors.newPasswordChecker}
                        sx = {{gridColumn:"span 2"}}
                    />
                    <TextField 
                        fullWidth 
                        variant="outlined" 
                        type="password"
                        label="Senha Atual" 
                        onBlur={formikAlterarSenha.handleBlur} 
                        onChange={formikAlterarSenha.handleChange}
                        name="password" 
                        value={formikAlterarSenha.values.password}
                        error = {!!formikAlterarSenha.touched.password && !!formikAlterarSenha.errors.password}
                        helperText={formikAlterarSenha.touched.password && formikAlterarSenha.errors.password}
                        sx = {{gridColumn:"span 2"}}
                    />
                    <Button 
                        type="submit" 
                        color="secondary"
                        variant="contained" 
                        endIcon={< PasswordIcon/>} 
                        style={{minWidth :'260px'}} 
                        sx = {{gridColumn:"span 2"}}
                        disabled={!formikAlterarSenha.isValid || formikAlterarSenha.isSubmitting }
                    >
                        Alterar senha
                    </Button>
                </Box>
            </form>
            <Snackbar autoHideDuration={4000} open={open} onClose={handleCloseAlert} anchorOrigin={{vertical:'top', horizontal:'right'}}>
                <Alert elevation={6} severity={status} onClose={handleCloseAlert}>{message}</Alert>
            </Snackbar>
        </>
    )
};
export default ChangePassword;
