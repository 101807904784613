import { useState, useEffect } from 'react';

import api from '../../api';
//import history from '../../history';

export default function useAuth() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const user = localStorage.getItem('user');
    
    if (user) {
        let u = JSON.parse(user);
        api.defaults.headers.Authorization = `Bearer ${u.token}`;
        setAuthenticated(true);
    }

    setLoading(false);
  }, []);
  
  async function handleLogin(email,password) {
    try{
        const response = await api.post(`server/auth`, {email:email, password:password, action:'login'});
        
        if (response.data.message && response.data.message.token) {
            var user = response.data.message;
            localStorage.setItem('user', JSON.stringify(user));
            api.defaults.headers.Authorization = `Bearer ${user.token}`;
            setAuthenticated(true);
            window.location = "/";
        }
    }
    catch (error){
        return error.response.data;
    }
  }

  async function handleResetPassword(email) {
    try{
        const response = await api.post(`server/auth`, {email:email, action:'resetPassword'});
        
        console.log(response)
        
        return response.data 
    }
    catch (error){

        return error.response.data;
    }
  }

  function handleLogout() {
     setAuthenticated(false);
     localStorage.removeItem('user');
     api.defaults.headers.Authorization = undefined;
     window.location = "/login";
  }
  
  return { authenticated, loading, handleLogin, handleLogout, handleResetPassword };
}